.transhipment-info-group{

}

.transhipment-info-content{
    width: 100%;
    padding-left: 30px;
}

.step-content.is-form ion-list.transhipment-info-content{
    padding-bottom: 30px !important;
}