.pir-inspection-progress-bar{
    .signature{
        position: absolute;
        z-index: 1;
        left: 80%;
        color: #434343;
        margin-top: -6px;
        background-color: var(--ion-color-warning);
        border-radius: 10px;
        width: 16px;
        height: 16px;

        ion-icon{
            font-size: var(--font-size-5);
            position: relative;
            margin-top: -5px;
            margin-left: 3px;
        }
    }
}