ion-list{
    &.open-photoswipe{
        contain:none
    }
}

.pir-attachment-picture-item{
    ion-reorder{
        margin-right: 0;
        padding-right: 30px;
        height: 60px;
        display: flex;
        align-items: center;
        ion-icon{
            width: 25px;
            height: 25px;
        }
    }
}