.vessel-files-page{
    ion-header{
        // background-color: red;
        // --ion-toolbar-background: transparent;
    }

    ion-item{
        &.attachment{
            padding-left: 10px;
        }
        ion-badge{
            margin-right: 10px;
        }
        ion-icon{
            margin-right: 10px;
        }
    }

    ion-content{
        &.open-photoswipe{
            z-index: 10;
        }
    }
}