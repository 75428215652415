
.pir-calendar-item-content{
    display: flex;
}

ion-card.pir-calendar-item{
    border-radius: 20px;
    height: 40px;

    ion-thumbnail{
        --size: 40px;
        --border-radius:20px;
        margin-right: 5px;
    }
    ion-card-title{
        font-size: var(--font-size-6);
        font-weight: 700;
    }

    ion-label{
        font-size: var(--font-size-7);
        font-weight: 400;
    }
}
.pir-calendar-item-right{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-right: 15px;
}