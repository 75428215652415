.custom-fab-list{
    position: sticky;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;

    ion-fab:nth-child(2){
        margin-right: 65px;
    }
}

.item-detail{
    display: inline-flex;
    margin-right: 15px;
    font-size: var(--font-size-7);
    font-weight: 500;
    color: var(--ion-color-medium-shade)
}

.page-bottom-spacer{
    height: 100px;
    width: 100px;
}

.step-content{
    padding: 30px;
    width: 100%;
    ion-title{
        color: red;
        font-weight: 700;
        font-size: var(--font-size-1);
        padding-left: 0px;
        margin-bottom: 30px;
        color: var(--ion-color-dark-shade)
    }
    &.is-form{
        padding-left: 0px;

        .yes-no-select{
            flex:1
        }
        
        .step-description{
            font-size: var(--font-size-6);
            margin-bottom: 30px;
            color: var(--ion-color-dark-tint);
        }

        & > *{
            padding-left: 30px;
        }

        ion-list{
            padding-bottom: 300px;
        }
        
        .select-viewer.mode-preview{
            flex: 4;
            text-align: end;
        }

        ion-title{
            .title-content{
                white-space: normal;
            }
        }

        .item-description{
            font-size: var(--font-size-7);
            margin-top: 5px;
            color: var(--ion-color-dark-tint);
            padding-left: 15px;
            margin-bottom:30px;
        }

        ion-item{

            ion-item-group{
                width: 100%;
            }

            ion-label{
                    white-space: break-spaces;
                    overflow: visible;
                
            }

            ion-input{
                text-align: right;
            }
            
            ion-label:not(.input-like){
                --color: var(--ion-color-dark-tint);
                font-weight: 800;
                font-size: var(--font-size-6);
            }

            ion-label.input-like{
                text-align: right;
            }
        }
        ion-item-divider{
            font-weight: bold;
            letter-spacing: 0.05rem;
            font-size: var(--font-size-7);
            border-bottom: none;
            position: sticky;
            top: 0;
            margin-bottom: 10px;

            ion-label{
                text-transform: uppercase;
            }
        }
        ion-item-group{
            margin-top: 70px;

            &:first-child{
                margin-top: 0;
            }
        }
    }
}