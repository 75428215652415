.pir-page-content{
    display: flex;
    height: 100%;
}

.pir-page{
    --ion-background-color: var(--ion-card-background);

    ion-card{
        border: none;
        border-radius: 0;
        box-shadow: none;
    }

    ion-card-header{
        border: none;
        min-height: 0;
    }

    ion-card-content{
        min-height: 0px;
    }
}

.pir-page-left-top{
    border-bottom: var(--border);
    padding-bottom: 25px;
}

.pir-page-left{
    width: var(--left-bar);
    border-right: 1px solid var(--border-color);
    height: 100%;
    display: flex;
    flex-direction: column;
}

.pir-page-right{
    overflow: auto;
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    // padding-bottom: 80px;
}

.pir-page-left-bottom{
    height: 100%;
    overflow: auto;
    padding-top: 35px;
    padding-bottom: 20px;
}

.vessel-file-button{
    position: relative;
    ion-fab{
        top:-2px;
    }
}