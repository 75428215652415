.page-reports{
    ion-content{
        --padding-top: 70px;
        --padding-bottom: 70px;
        --padding-start: 60px;
        --padding-end: 60px;
        --reverse-margin: -60px;
    }

    h1{
        margin-bottom: 49px;
        font-weight: 700;
    }

    h2{
        font-size: var(--font-size-2);
        color: var(--ion-color-medium-tint);
        margin: 0;
        font-weight: 500;
    }

    .pir-slider-title{
        display: flex;
        margin-bottom: 36px;
        align-items: center;

        h2{
            line-height: 0;
        }

        ion-button{
            margin: 0;
            margin-left: 10px;
        }
    }

    ion-slides{
        margin-right: var(--reverse-margin);
        overflow-x: visible;
        padding-left: var(--padding-start);
        margin-left: var(--reverse-margin);
        padding-top: var(--shadow-safe-size);
        padding-bottom: var(--shadow-safe-size);
        margin-top: var(--shadow-safe-size-reverse);
        margin-bottom: 60px;
    }

    ion-grid{
        padding: 0;
    }

    ion-col{
        padding: 0;
    }

    ion-searchbar{
        padding: 0;
    }

    .filtering{
        margin-top: -44px;
    position: absolute;
    }

    .reports-toolbar{
        margin-bottom: 49px;

        ion-button{
            position: relative;
            .filter-badge{
                display: block;
                position: absolute;
                height: 6px;
                width: 6px;
                border-radius: 6px;
                top: 5px;
                right: -3px;
                padding: 0;
                background: var(--ion-color-danger);
            }
        }
    }
}

.pir-slider-modal{
    ion-title{
        .title-content{
            white-space: normal;
        }
    }
}