.status-badge{
    height: 25px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    border-radius: 15px;
    margin-left: 10px;
    padding: 0;
    padding-right: 15px;
    padding-left: 13px;
    display: flex;
    align-items: center;

    ion-icon{
        width: 1.2em;
        height: 1.2em;
        margin-right: 9px;
    }

    ion-text{
        font-size: var(--font-size-8);
        margin-top: 1px;
    }
}

.app-status-item{
    height: 100%;
    display: flex;
    align-items: center;

    .logo-wrapper{
        width: 36px;
        height: 36px;
    }
}