.signature-field-wrapper{
    width: 100%;
    flex: 1;
    height: -webkit-fill-available;

    &>div{
        width: 100% !important;
    }

    canvas{
        background: #f2f2f2;
        margin-top: 10px;
        border-radius: 5px;
    }
}