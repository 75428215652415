ion-list{
    &.open-photoswipe{
        contain:none
    }
}


.form-vessel-picture-group{
    display: flex;
    margin-top: 90px !important;

    ion-item-divider{
        margin-top: -40px;
        margin-bottom: -5px !important;
        background: transparent;
    }

    ion-thumbnail{
        flex: 1;
        min-width: 100px;
    }

    .pir-group-info{
        flex: auto;
        width: calc(100% - 120px);
    }
}