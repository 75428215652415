.claimed-button{
    
    ion-item{
        --inner-border-width:0;
    }

    ion-icon{
        min-width: 20px;
        height: 20px;
        margin-left: 5px;
    }

    .claimed-button-content{
        flex-direction: column;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .claimed-button-head{
        flex-direction: row;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        ion-label{
            white-space: normal;
        }
    }

    .claimed-button-footer{
        width: 100%;
        min-height: 4px;
        padding: 1px;
        background: white;
    }

    ion-progress-bar{
    }

    ion-item.claimed-button-status{
        --border-radius: 10px;
        width: 300px;
        --padding-top: 10px;
        --padding-bottom: 10px;
    }
}