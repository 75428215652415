.login-page{

    ion-toolbar{
        --border-color: transparent;
    }

    ion-content{
        --padding-top: 70px;
        --padding-bottom: 70px;
        --padding-start: 60px;
        --padding-end: 60px;
        --reverse-margin: -60px;
    }

    h1{
        margin-bottom: 49px;
        font-weight: 700;
    }

    ion-item{
        padding-top: 20px;
        margin-bottom: 20px;
    }

    ion-list{
        margin-bottom: 30px;
    }
}