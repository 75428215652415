.configuration-modal{
    .title-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;

        .title-text{
            display: block;
        }

        ion-icon{
            margin-right: 10px;
        }
    
    }
}
.configuration-modal-content{
    ion-item{
        --background: transparent;
        padding: 20px;
    }

    .configuration-item{
        padding: 25px;
        border: var(--border);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .configuration-item-content{
            
            & > ion-label{
                padding-left: 10px;
                margin-bottom: 10px;
                display: block;
            }
        }
    }
}