ion-app{
  --border-color: rgb(196, 202, 206);
  --border: 1px solid var(--border-color);
  --shadow-color: rgba(0, 0, 0, 0.25);
  --shadow-safe-size: 30px;
  --shadow-safe-size-reverse: -30px;
  --shadow-size: 22px;
  --box-shadow: 0px 4px var(--shadow-size) -8px var(--shadow-color);
  --box-shadow-activated: 0px 4px 4px -8px var(--shadow-color);
  --ion-color-medium-tint: rgba(70, 74, 80, 1); //override
  --font-color-3: rgba(152, 159, 165, 1);

  &.dark{
    background: var(--ion-background-color);
      --border-color: #0e0e0e;
      --border: 1px solid var(--border-color);
      --shadow-color: black;
      --ion-color-medium-tint: #cfcfcf; //override
      --font-color-3: rgba(152, 159, 165, 1);
  }
}

ion-tab-bar{
  --border: inherit;
  border-top: var(--border);
}

ion-tab-button {
  
  &.tab-status {
    margin-right: auto;
    align-items: flex-start;
  }

  &.tab-user {
    margin-left: auto;
    align-items: flex-end;
  }

}

.main-tab-bar{
  height: 70px;
  padding: 0 30px;
}

.side-tab{
  max-width: 240px;
  flex:3;
  --padding-top: 0;
  --padding-end: 0px;
  --padding-bottom: 0;
  --padding-start: 0px;
}

.sc-ion-searchbar-md-h {
  --background: var(--ion-background-color, #fff);
  --border-radius: 30px;
  --box-shadow: 0 0 0 1px var(--border-color);
}

.tabs-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .tabs-content{
    height: 100%;
    position: sticky;
  }
}

.fab-button-list{
  display: flex;
  position: fixed;

  ion-fab-button{
    margin-left: 10px;
  }
}
ion-fab-button.ion-fab-button-with-label{
  width: 200px;
  --border-radius: 30px;
  white-space: normal;

  &.fab-button-in-list{
    margin-left: -100px;
    width: 150px;

    ion-label{
      font-size: var(--font-size-7);
      margin-left: 15px;
      margin-right: 5px;
    }
  }

  ion-label{
    flex: 3;
    margin-left: 25px;
    margin-right: 10px;
    text-align: left;
    font-size: var(--font-size-5);
    font-weight: 500;
  }

  ion-icon{
    flex: 1;
    margin-right: 15px;
  }
}


ion-toast{
    --ion-safe-area-bottom: 68px;
    left: 0;
    width: 400px;
    left: 20px;
    right: auto;
}

ion-thumbnail{
  --border-radius: 5px;
  margin-right: 15px;
}
