
.pir-card-wrapper{
    position: relative;
    width: 100%;

    &.placeholder{
        .pir-card{
            height: 230px;
            border: none;
            opacity: 0.5;
        }
    }

    & > .badge{
        cursor: pointer;
        position: absolute;
        z-index: 1;
        right: -5px;
        top: -5px;
        width: 26px;
        height: 26px;
        border-radius: 20px;
        background: var(--ion-color-warning);
        color: var(--ion-color-warning-contrast);

        ion-icon{
            position: relative;
            top: 4px;
            left: 0px;
        }
    }
}
.pir-card{
    text-align: left;
    margin: 0;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    width: 100%;
    transform: translateY(0px);
    transition: box-shadow ease-in .01s, transform ease-in .01s;
    

    &.ion-activated{
        box-shadow: var(--box-shadow-activated);
        position: relative;
        transform: translateY(1px);
    }

    ion-card-content{
        padding: 8px 0;
        min-height: 120px;
    }

    ion-item{
        --color: var(--ion-color-medium-tint);
        --min-height: 35px;
        --background: transparent;

        .multi-label{
            max-width: calc(100% - 32px);
        }

        ion-icon{
            width: 20px;
            height: 20px;
            margin-right: 12px;
        }
        ion-label{
            font-size: var(--font-size-5);
            font-weight: 700;
            margin: 0;

            &.small{
                font-weight: 400;
                font-size: var(--font-size-7);
                margin-bottom: -5px;
            }
        }
    }

    ion-card-title{
        font-size: var(--font-size-4);
        font-weight: normal;
        min-height: 30px;
    }

    ion-thumbnail{
        width: 70px;
        height: 70px;
        background: #C4C4C4;
        border-radius: 5px;
    }

    ion-progress-bar{
        position: absolute;
        margin-top: -1px;
        height: 4px;
    }
}

.pir-card-header{
    display: flex;
    justify-content: space-between;
    border-bottom: var(--border)
}

.pir-card-header-left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
}

.pir-card-footer{
    display: flex;
    justify-content: flex-end;
}

.pir-card-link{
    margin: 0;
    --padding-start: 15px;
    --padding-end: 15px;
    --ion-color-base: var(--font-color-3) !important;
    --color-activated: var(--font-color-3);
    --color-focused: var(--font-color-3);
    --color-hover: var(--font-color-3);
    text-transform: none;
    letter-spacing: 0;
}