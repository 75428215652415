ion-menu[menu-id="user-menu"]{

    ion-list{
        background: none;
    }
    ion-footer{
        padding: 15px;
    }
    ion-toolbar{
        padding: 15px;
        text-align: center;
    }
    ion-title{
        margin-top: 10px;
        .title-content{
            white-space: normal;
        }
    }

    ion-toggle{
        pointer-events: initial !important;
    }
    ion-content.menu-content-open ion-item{
        pointer-events: all;
    }
}