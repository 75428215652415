.task-info-toggle{
    width: 30px;
    height: 30px;
    --padding-top: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    font-size: var(--font-size-9);
}

ion-list.process-component{
    padding-top: 0;
    padding-bottom: 0;

    &.list{
        margin-top: 30px;
        ion-item{
            --padding-start:30px;
        }
    }

    ion-item{
        --background:transparent;
        --padding-start:0;
        --padding-end:0;
        font-size: var(--font-size-7);
        margin-bottom: 5px;
        overflow: visible;
        
        ion-icon{
            width:25px;
        }
    }

    ion-label{
        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 5px;
    }

    ion-progress-bar{
        border-radius: 10px;
    }

    .task-content{
        margin-left: 10px;
        display: flex;
        width: 100%;
        flex-direction: column;
        position: relative;

        .completion-details{
            position: absolute;
            top: 100%;
            left: 0;
        }
    }
}

.process-mini-viewer-wrapper{
    .error-icon{
        position: absolute;
        z-index: 1;
        left: 80%;
        color: var(--ion-color-danger-contrast);
        margin-top: -11px;
        background-color: var(--ion-color-danger);
        border-radius: 20px;
        width: 24px;
        height: 24px;

        ion-icon{
            font-size: var(--font-size-5);
            position: relative;
            margin-top: 2px;
            margin-left: 4px;
        }
    }
}