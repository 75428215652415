.pir-status-badge{
    --padding-top: 0px;
    --padding-end: 15px;
    --padding-bottom: 0px;
    --padding-start: 15px;
    border-radius: 15px;
    height: 25px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: var(--font-size-8);
    font-weight: 700;
    line-height: 0;

    &.disabled{
        opacity: 0.5;
    }

    ion-icon{
        margin-left: -5px;
        margin-right: 5px;
        width: 14px;
        height: 14px;
    }
}