ion-list.pir-inspection-item-popover{
    padding:0;

    &.info{
        padding-top:10px;
        padding-bottom: 10px;
    }
}

.pir-inspection-item{
    position: relative;
    z-index: 2;
    
    .button-wrapper{
        position: absolute;
        z-index: 3;
        left: -20px;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;

        &.right{
            right: 0px;
            left: auto;
            align-items: flex-start;
            margin-top: 5px;
        }
    }

    ion-button.error-toggle{
        width: 30px;
        height: 30px;
        --padding-top: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-bottom: 0;
        font-size: var(--font-size-9);
    }

    ion-button.info-toggle{
        width: 30px;
        height: 30px;
        --padding-top: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-bottom: 0;
        font-size: var(--font-size-9);
    }

    .mandatory{
        position: absolute;
        top: 0;
        left: 8px;
        z-index: 2;
    }

    ion-item{
        --inner-padding-end: 40px;
        &.with-error{
            --color: red;
        }
    }

    &.disabled{
        // .item-description{
        //     opacity: 0.3;
        // }

        ion-item{
            opacity: 1;
            & > * {
                opacity: 0.3;
            }
            ion-label{
                opacity: 1;
            }
        }
    }

    ion-item{
        &.item-disabled{
            opacity: 1;
            & > * {
                opacity: 0.3;
            }
            ion-label{
                opacity: 1;
            }
        }
        // &.item-interactive-disabled[disabled="true"]{
        //     opacity: 1;
        //     & > * {
        //         opacity: 0.3;
        //     }
        //     ion-label{
        //         opacity: 1;
        //     }
        // }
    }
}