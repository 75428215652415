.calendar-day{
    display: flex;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    width: 100%;
    transform: translateY(0px);
    transition: box-shadow ease-in .01s, transform ease-in .01s;
    height: calc((100% / 7) - 10px);
    min-height: 50px;
    align-items: center;
    margin-bottom: 8px;

    &.today{
        &:before{
            content: "";
            display: block;
            position: absolute;
            pointer-events: none;
            top: -3px;
            right: -3px;
            left: -3px;
            bottom: -3px;
            border: 3px solid var(--ion-color-primary-tint);
            border-radius: 10px;
        }
    }
    &.past{
        opacity: 0.7;
    }
}

.calendar-date{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    padding-right: 10px;
    width: 10%;
    position: relative;

    .month-year{
        text-transform: uppercase;
        font-size: var(--font-size-8);
    }

    .calendar-number{
        font-size: var(--font-size-2);
    }

    .day-of-the-week{
        font-size: var(--font-size-8);
    }
}

.calendar-page{
    ion-content{
        --padding-top: 30px;
        --padding-bottom: 30px;
        --padding-start: 30px;
        --padding-end: 30px;
    }

    .calendar{
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
}