.user-icon{
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    ion-icon{
        transform-origin: center center;
        font-size: var(--font-size-2);
    }

    &.update-available{
        &:before{
            content:"";
            display: block;
            position: absolute;
            width: 6px;
            height: 6px;
            background: var(--ion-color-success-shade);
            z-index: 1;
            top: 8px;
            margin-right: -20px;
            border-radius: 3px;
        }
    }
}