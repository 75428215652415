.react-pdf__Document{
    overflow: auto;
}

.pdf-file-wrapper{
    width: 100%;
    flex: 1;
    height: -webkit-fill-available;

    &>div{
        width: 100% !important;
    }
}

.react-pdf__Page{
    margin-bottom: 50px;
}

ion-fab.pdf-zoom{
    display: flex;
    ion-fab-button{
        margin-left: 10px;
    }
}