.pir-step-link{
    text-decoration: none;
}

.forms-completed{
    .pir-step.is-form{
        .rail{
            &.completed{
                display: block;
                background: var(--ion-color-success-shade);
                z-index: 0;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}

.pir-step{
    padding: 10px;
    display: flex;
    position: relative;
    min-height: 35px;
    align-items: center;
    z-index: -1;

    --label-color: var(--ion-color-medium);
    --check-color: var(--ion-color-dark-tint);
    --check-border-color: var(--ion-color-dark-tint);
    --date-color: var(--ion-color-medium);
    --label-size: 14px;

    &:not(.disabled){
        --label-color: var(--ion-color-dark-shade);
    }

    ion-label{
        font-weight: 500;
        font-size: var(--font-size-6);
        color: var(--label-color);
    }

    .rail-check{
        position: absolute;
        width: 13px;
        height: 13px;
        top: 50%;
        left: 20px;
        border-width: 1px;
        border-style: solid;
        border-color: var(--check-border-color);
        background: var(--check-color);
        border-radius: 13px;
        margin-top: -6px;
        z-index: 1;
    }

    &.adding{
        .step-link-content-head{
            .badge-wrapper{
                width: 18px;
                height: 18px;
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    height: 36px;
                    width: 36px;
                    top: -9px;
                    left: -9px;
                    animation: pulse 1s ease infinite;
                    border-radius: 50%;
                    background: var(--ion-color-success-shade);
                }

                @keyframes pulse {
                    0% {
                        transform: scale(0.2);
                        opacity: 0.8;
                    }
                    80% {
                        transform: scale(1.2);
                        opacity: 0;
                    }
                    100% {
                        transform: scale(2.2);
                        opacity: 0;
                    }
                }
            }
            ion-badge{
                overflow: visible;
            }
        }
    }

    .step-link-content{
        display: flex;
        flex-direction: column;
        width: 100%;

        .step-link-content-head{
            display: flex;
            align-items: center;
            width: 100%;

            ion-label{
                flex:auto;
            }

            ion-icon{
                flex:1;
                color: var(--label-color);
                max-width: 15px;
            }

            ion-badge{
                font-size: var(--font-size-8);
                width: 18px;
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                vertical-align: middle;
                position: relative;
                transition: background-color 0.3s ease-in-out;
            }
        }

        .step-date{
            margin-top: 2px;
            font-size: var(--font-size-8);
            display: flex;
            align-items: center;
            color: inherit;
            color: var(--date-color);

            ion-icon{
                margin-right: 5px;
                color: var(--date-color);
            }
        }
    }

    &.selected{
        background: var(--ion-color-light-tint);
    }

    &.is-default:before{
        content: "";
        display: block;
        position: absolute;
        border: 3px solid var(--ion-color-primary-tint);
        border-radius: 5px;
        top: 3px;
        bottom: 3px;
        left: 3px;
        right: 3px;
    }

    &.first{
        .rail{
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }

    &.last{
        .rail{
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    &.is-signature{
        --label-color: var(--ion-color-dark-shade);

        &.disabled{
            --label-color: var(--ion-color-medium);
        }
    }

    &.is-form{
        --label-color: var(--ion-color-dark-shade);
        --label-size: 14px;
        --check-border-color: var(--ion-color-dark-tint);

        .rail{
            &.completed{
                display: none;
            }
        }

        &.is-default:before{
            display: none;
        }

        .rail-check{
            width: 20px;
            height: 20px;
            left: 16px;
            border-radius: 13px;
            margin-top: -10px;
            border: 1px solid;
            border-color: var(--check-border-color);
            background: white;
            display: flex;
            align-items: center;
            justify-content: center;

            ion-icon{
                margin: 0;
            }
            
        }

        &.disabled{
            --label-color: var(--ion-color-medium);
            --check-border-color: var(--ion-color-medium);
        }
    }

    &.completed{
        --check-border-color: var(--ion-color-success-shade);
        
        .rail-check{
            background-color: white;
        }

        &.is-form{
            .rail-check{
                color: var(--ion-color-success-shade);
            }
        }
    }

    &.has-error{
        --check-border-color: var(--ion-color-danger-shade);
        --label-color: var(--ion-color-danger-shade);
        --check-border-color: var(--ion-color-danger-shade);
    }

    .step-rail{
        width: 45px;
    }

    .rail{
        position: absolute;
        top: 0;
        width: 7px;
        height: calc(100% + 5px);
        left: 23px;

        &.base{
            background: #E5E5E5;
            z-index: -1;
        }

        &.completed{
            background: var(--ion-color-success-shade);
            z-index: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}